<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <div class="q-pa-md">
      <pro-form-render
        mode="Edit"
        :code="WorkflowCode"
        @pro-form-submit="
          () => this.$router.push({ name: 'SupplierManagementList' })
        "
      />
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  components: { ProDocumentBreadcrumbs, ProFormRender },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.SupplierManagement",
          icon: "PROSmart-Account",
          to: { name: "SupplierManagementList" },
        },
        { name: "menu.Batch-EditSupplierStatus" },
      ],
      WorkflowCode: "P_T_BatchEdit",
    };
  },
};
</script>
